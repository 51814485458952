import React, { useEffect, useRef, useState } from "react";
import { apiVersion, ProductType } from "../../../controller/api/apiCore";
import AddImage from "./AddImage";
import DateBox from "./DateBox";
import Input from "./Input";
import Select from "./Select";
import TextField from "./TextField";

function AddForm({
  onSubmit,
  history,
  handleImage,
  formState,
  setFormState,
  isDisabled,
}) {
  const [isMedicalEquipment, setIsMedicalEquipment] = useState(false);
  const [isMedicalDevice, setIsMedicalDevice] = useState(false);
  const [isConsumable, setIsConsumable] = useState(false);
  const [isDrug, setIsDrug] = useState(false);
  const [formulations, setFormulations] = useState([]);
  const [routeOfAdmins, setRouteOfAdmin] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const formRef = useRef(null);

  let {
    brand,
    generic,
    strength,
    //  formulation: formulations,
    price,
    manufacture_date,
    expiry_date,
    packsize,
    quantity,
    company,
    nafdac,
    description,
  } = formState;

  useEffect(() => {
    let {
      brand,
      generic,
      images,
      strength,
      formulation,
      price,
      manufacture_date,
      expiry_date,
      packsize,
      quantity,
      company,
      nafdac,
      description,
    } = JSON.parse(localStorage.getItem("formState")) || formState;

    setFormState({
      brand,
      generic,
      images,
      strength,
      formulation,
      price,
      manufacture_date,
      expiry_date,
      packsize,
      quantity,
      company,
      nafdac,
      description,
    });
    console.log(images);
    // handleImage.handleChange({ target: { files: images } });
  }, []);

  const handlePreview = async () => {
    const form = formRef.current;

    const state = {
      brand: form.brand?.value,
      generic: form.generic?.value,
      strength: form.strength?.value,
      formulation: form.formulation?.value,
      description: form.description?.value,
      expiry_date: form.expiry_date?.value,
      manufacture_date: form.manufacture_date?.value,
      packsize: form.packsize?.value,
      quantity: form.quantity?.value,
      nafdac: form.nafdac?.value,
      price: form.price?.value,
      company: form.company?.value,
      images: handleImage.selectedImages,
    };
    localStorage.setItem("formState", JSON.stringify(state));
    history.push({ pathname: "/merchant/preview", state });
  };

  const handleType = (type, value) => {
    console.log(type);
    switch (type) {
      case 1: //consumable
        getCategory(value);
        setIsDrug(false);
        setIsMedicalDevice(false);
        setIsMedicalEquipment(false);
        setIsConsumable(true);
        break;
      case 2: //medical device
        getCategory(value);
        setIsDrug(false);
        setIsMedicalDevice(true);
        setIsMedicalEquipment(false);
        setIsConsumable(false);
        break;
      case 3: //medical equipment
        getCategory(value);
        setIsDrug(false);
        setIsMedicalDevice(false);
        setIsMedicalEquipment(true);
        setIsConsumable(false);
        break;

      case 4:
        //drug
        getCategory(value);
        handleGetROA();
        setIsDrug(true);
        setIsMedicalDevice(false);
        setIsMedicalEquipment(false);
        setIsConsumable(false);
        break;

      default:
        setIsDrug(false);
        setIsMedicalDevice(false);
        setIsMedicalEquipment(false);
        setIsConsumable(false);
    }
  };

  // const handleFormulationOptions = (code) => {
  //   switch (code) {
  //     case 0: //oral
  //     handleGetFromulation(code);
  //       setFormulationOptions([
  //         { option: "Tablet", value: "tablet" },
  //         { option: "Caplet", value: "caplet" },
  //         { option: "Capsule", value: "capsule" },
  //         { option: "Syrup", value: "syrup" },
  //         { option: "Suspension", value: "suspension" },
  //         { option: "Drop", value: "drop" },
  //         { option: "Paste", value: "paste" },
  //         { option: "Mouth wash", value: "mouth wash" },
  //         { option: "Lozenge e.g(strepsil)", value: "lozenge" },
  //       ]);
  //       break;
  //     case 1: //rectal
  //       setFormulationOptions([
  //         { option: "Suppository", value: "suppository" },
  //         { option: "Cream", value: "cream" },
  //         { option: "Enema", value: "enema" },
  //       ]);
  //       break;
  //     case 2: //iv,im,im/iv,it,id
  //       setFormulationOptions([
  //         { option: "Ampoule", value: "ampoule" },
  //         { option: "Vial", value: "vial" },
  //         { option: "Pen", value: "pen" },
  //       ]);
  //       break;
  //     case 3: //sublingual
  //       setFormulationOptions([
  //         { option: "Tablet", value: "tablet" },
  //         { option: "Powder", value: "powder" },
  //       ]);
  //       break;
  //     case 4: //topical
  //       setFormulationOptions([
  //         { option: "Cream", value: "cream" },
  //         { option: "Paste", value: "paste" },
  //         { option: "Lotion", value: "lotion" },
  //         { option: "Spray", value: "spray" },
  //       ]);
  //       break;
  //     case 5: //ear nose eye
  //       setFormulationOptions([
  //         { option: "Ointment", value: "ointment" },
  //         { option: "Eye drop", value: "eye drop" },
  //         { option: "Ear drop", value: "ear drop" },
  //         { option: "Eye/Ear drop", value: "eye/ear drop" },
  //         { option: "Inhaler", value: "inhaler" },
  //         { option: "Nasal drop (Nose drop)", value: "nasal drop" },
  //       ]);
  //       break;
  //     case 6: //intravaginal
  //       setFormulationOptions([
  //         { option: "Pessary (insert)", value: "pessary" },
  //         { option: "Cream", value: "cream" },
  //         { option: "Douche", value: "douche" },
  //       ]);

  //       break;

  //     default:
  //       break;
  //   }
  // };

  const handleSort = (array) =>
    array.sort((a, b) =>
      a.option > b.option ? 1 : a.option < b.option ? -1 : 0
    );

  const handleGetFromulation = async (id) => {
    try {
      let req = await fetch(apiVersion + "/formulation/" + id);
      if (req.ok) {
        setFormulations(await req.json());
      }
    } catch (error) {
      console.log(error);
    }
  };

  //get route of administration
  const handleGetROA = async () => {
    try {
      let req = await fetch(apiVersion + "/roa");
      if (req.ok) {
        setRouteOfAdmin(await req.json());
      }
    } catch (error) {}
  };
  const getCategory = async (type) => {
    try {
      let req = await fetch(apiVersion + "/category/" + type);
      if (req.ok) {
        setCategories(await req.json());
      }
    } catch (error) {}
  };
  const getCompanies = async (type) => {
    try {
      let req = await fetch(apiVersion + "/brands");
      if (req.ok) {
        setCompanies(await req.json());
      }
    } catch (error) {}
  };
  useEffect(() => getCompanies(), []);
  return (
    <form
      encType="multipart/form-data"
      onSubmit={onSubmit}
      className="add-form"
      ref={formRef}
    >
      <h2>Add Product</h2>
      <Select
        label="Type"
        name="type"
        onChange={handleType}
        options={handleSort([
          { option: "Drug", value: ProductType.DRUG, code: 4 },
          { option: "*Select type of item", value: "" },
          {
            option: "Medical Equipment e.g wheel chair,knee support",
            value: ProductType.MEDICAL_EQUIPMENT,
            code: 3,
          },
          {
            option: "Medical Device e.g Accucheck, Omron etc",
            value: ProductType.MEDICAL_DEVICE,
            code: 2,
          },
          {
            option: "Consumable e.g drug envelopes",
            value: ProductType.CONSUMABLE,
            code: 1,
          },
        ])}
      />
      {isDrug && (
        <>
          {" "}
          <Select
            label="Category"
            name="primary_category"
            options={categories}
          />
          <div className="input-con-wrap">
            <Input
              type="text"
              name="brand_name"
              label="Brand Name"
              id="brand_name"
              placeholder="e.g Augmentin"
              value={brand}
            />
            <Input
              type="text"
              name="generic_name"
              placeholder="e.g Amoxicillin + Clavulanic acid"
              label="Generic Name"
              id="generic_name"
              value={generic}
            />
          </div>
          <div className="input-con-wrap">
            <Input
              type="text"
              name="strength"
              placeholder="625mg"
              label="Strength"
              value={strength}
            />
            <Select
              type="text"
              name="roa"
              label="Route Of Administration"
              group={true}
              id="formulation"
              selectFormulation={handleGetFromulation}
              value={formulations}
              options={routeOfAdmins}
            />
          </div>
          <div className="input-con-wrap">
            {formulations.length > 0 && (
              <Select
                name="formulation"
                options={formulations}
                label="Formulation"
              />
            )}{" "}
            <Input
              name="packsize"
              placeholder="14"
              value={packsize}
              label="Packsize"
              type="number"
            />{" "}
          </div>
        </>
      )}
      {isConsumable && (
        <>
          <div className="input-con-wrap">
            <Input type="text" label="Name" name="name" />
            <Select
              label="Category"
              name="primary_category"
              options={categories}
            />
          </div>
        </>
      )}
      {isMedicalDevice && (
        <>
          <div className="input-con-wrap">
            <Input label="Name" type="text" name="name" />
            <Select
              label="Category"
              name="primary_category"
              options={categories}
            />
          </div>
        </>
      )}
      {isMedicalEquipment && <Input type="text" name="name" label="Name" />}
      <div className="input-con-wrap">
        <Input
          name="quantity"
          value={quantity}
          label="Quantity"
          type="number"
        />
        <Input name="price" value={price} label="Price" type="number" />
      </div>
      <div className="input-con-wrap">
        <div className="input-con">
          <label htmlFor="Company">Company</label>
          <select name="company">
            {companies?.map((option) => (
              <option
                key={option.option ?? option._id}
                value={option.value ?? option.name}
              >
                {option.name}
              </option>
            ))}
          </select>
        </div>
        <Input
          type="text"
          label="Nafdac Number"
          name="nafdac"
          placeholder="234dcf"
          value={nafdac}
        />
      </div>

      <DateBox values={{ expiry_date, manufacture_date }} />

      <TextField value={description} label="Description" />
      <AddImage handleImage={handleImage} />

      <div className="input-con">
        <input
          type="submit"
          disabled={isDisabled}
          value={isDisabled ? "Please Wait" : "Add Product"}
        />
      </div>
    </form>
  );
}

export default AddForm;
