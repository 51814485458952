import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./customer.scss";
import { apiVersion } from "../../../controller/api/apiCore";
import { useSelector } from "react-redux";
import Loader from "../../reusables/Loader";
const Customer = () => {
  const param = useParams();
  let [loading, setLoading] = useState(true);
  let token = useSelector((state) => state.user.token);
  let [customer, setCustomer] = useState({});

  let verifyCustomer = async () => {
    setLoading(true);
    let req = await fetch(apiVersion + "/customer/" + param.id, {
      headers: { Authorization: "Bearer " + token },
      method: "PATCH",
    });
    if (req.ok) {
      let res = await req.json();
      setCustomer(res);
    }
    setLoading(false);
  };
  useEffect(() => {
    (async () => {
      let req = await fetch(apiVersion + "/customer/" + param.id, {
        headers: { Authorization: "Bearer " + token },
      });
      if (req.ok) {
        let res = await req.json();
        setCustomer(res);
      }
      setLoading(false);
    })();
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <div className="customer-con">
      <div className="customer-profile-con">
        <div className="customer-info">
          <p>First Name</p>
          <p>{customer.firstname}</p>
        </div>
        <div className="customer-info">
          <p>Last name</p>
          <p>{customer.lastname}</p>
        </div>
        <div className="customer-info">
          <p>Company Name</p>
          <p>{customer.business_name}</p>
        </div>
        <div className="customer-info">
          <p>Sex</p>
          <p>{customer.sex}</p>
        </div>
        <div className="customer-info">
          <p>Email Address</p>
          <p>{customer.email}</p>
        </div>
        <div className="customer-info">
          <p>Phone</p>
          <p>{customer.phone_number}</p>
        </div>
        <div className="customer-info">
          <p>Registeration Number</p>
          <p>{customer.reg_number}</p>
        </div>
        <div
          className={
            "customer-info " +
            (customer.is_verified ? "verified" : "unverified")
          }
        >
          <p>Status</p>
          <p>{customer.is_verified ? "Verified" : "Unverified"}</p>
        </div>
        <div className="profile-buttons">
          <button onClick={() => verifyCustomer()}>Verify Customer</button>
          <button>Update Customer</button>
        </div>
      </div>
    </div>
  );
};

export default Customer;
