import React from "react";
import { ReactComponent as Delete } from "../../../../../../res/images/cancel.svg";

function Table({ paged, prev, handleToEdit, handleDeleteProduct }) {
  return (
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>
            <div className="table-head">Type</div>
          </th>
          <th>
            {" "}
            <div className="table-head">Name</div>
          </th>

          <th>
            {" "}
            <div className="table-head">Quantity</div>
          </th>
          <th>
            {" "}
            <div className="table-head">
              <p title="Unit Price">UP(&#8358;)</p>
            </div>
          </th>
          <th>
            {" "}
            <div className="table-head">
              <p title="Bulk Price">BP(&#8358;)</p>
            </div>
          </th>
          <th>
            {" "}
            <div className="table-head">
              <p title="Amount Sold">AS</p>
            </div>
          </th>
          <th>
            <div className="table-head">
              <p title="Total Sales">TS</p>
            </div>
          </th>
          <th>
            <div className="table-head">
              <p title="If the item is visible to customers">IsVisible</p>
            </div>
          </th>
          <th>
            <div className="table-head"></div>
          </th>
        </tr>
      </thead>
      <tbody>
        {paged.map((item, i) => (
          <tr key={i + item.quantity} onClick={(e) => handleToEdit(item)}>
            <td>{i + prev + 1}</td>
            <td>{item.type}</td>
            <td>
              {item.name
                ? item.name + " " + item.strength
                : item.brand_name + " " + item.strength}
            </td>
            <td>{item.quantity}</td>
            <td>{item.price ?? 0}</td>
            <td>{item.quantity * item.price}</td>
            <td>{item.quantity_sold ?? 0}</td>

            <td>{item.total_sale ?? 0}</td>
            <td>{item.isVisible ? "True" : "False"}</td>
            <td onClick={(e) => handleDeleteProduct(e, item)}>
              <Delete />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
