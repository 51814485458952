import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "products",
  initialState: [],
  reducers: {
    addProducts: (state, { payload }) => payload,
  },
});
export const { addProducts } = slice.actions;
export default slice.reducer;
