import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "user",
  initialState: JSON.parse(localStorage.getItem("user")) || {
    isAuthorized: false,
    token: "",
  },
  reducers: {
    loginUser: (state, { payload }) => {
      state = { ...payload, isAuthorized: true };
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
    logoutUser: (state, { payload }) => {
      state = { isAuthorized: false, token: "" };
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
  },
});

export const { loginUser, logoutUser } = slice.actions;
export default slice.reducer;
