import React, { useEffect, useState } from "react";
import PaginationHead from "../reusables/PaginationHead";
import PaginationPages from "../reusables/PaginationPages";
import SortBar from "../SortBar";
import { apiVersion } from "../../../controller/api/apiCore";
import Table from "./components/Table";
import { useNavigate } from "react-router-dom";
import Loader from "../../reusables/Loader";

function AdminMerchants(props) {
  const [loading, setLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setCount] = useState({ prev: 0, next: 10 });
  const { prev, next } = pageCount;
  const navigate = useNavigate();
  const [merchants, setMerchants] = useState([]);
  const [paged, setPaged] = useState([]);
  const [showMenu, setShowMenu] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });
  const deFaultMenu = {
    0: false,
    1: false,
    2: false,
    3: false,
  };

  const getMerchants = async () => {
    setLoading(true);
    try {
      let req = await fetch(apiVersion + "/merchants");
      if (req.ok) {
        let merchants = await req.json();
        setMerchants(merchants);
        setPaged(merchants.slice(0, 10));
      }
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    //  setMerchants(rawMerchants);
    getMerchants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSortMenu = (
    { target },
    key,
    reverse,
    filter,
    rawArray,
    stateArray,
    pagedArray
  ) => {
    let list = document.getElementsByClassName("sort-option");
    for (const i of list) i.classList.remove("sort-active");
    target.classList.add("sort-active");
    setShowMenu(deFaultMenu);
    if (!filter) {
      const sorted = merchants.sort((a, b) =>
        a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0
      );
      reverse ? setMerchants(sorted.reverse()) : setMerchants(sorted);
      setPaged(sorted.slice(prev, next));
      return;
    }
    const sorted = merchants.filter(({ rating }) => rating === filter);
    setMerchants(sorted);
    setPaged(sorted.slice(prev, next));
    console.log(sorted);
    console.log(
      `%c ${sorted.reverse()}
      ${merchants.sort().reverse()}`,
      "background:red"
    );
  };

  const handleSearch = async ({ target }) => {
    let query = new RegExp(target.value, "i");
    const filtered = merchants.filter(({ bn }) => bn.match(query));
    await setMerchants(filtered);
    await setPaged(filtered.slice(0, 10));
  };

  const handleShowMenu = (code) => {
    let newMenu = { ...deFaultMenu, [code]: true };
    !showMenu[code] ? setShowMenu(newMenu) : setShowMenu(deFaultMenu);
  };

  const handleToMerchant = (merchant) => {
    navigate("/admin/merchant-account/" + merchant._id);
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="admin-merchant-parent">
      <SortBar
        handleSearch={handleSearch}
        menu={showMenu}
        onSort={{ handleSortMenu, handleShowMenu }}
      />

      <PaginationHead
        array={merchants}
        next={next}
        prev={prev}
        setPageIndex={setPageIndex}
        setPaged={setPaged}
        setCount={setCount}
      />
      <Table handleToMerchant={handleToMerchant} paged={paged} prev={prev} />
      <PaginationPages
        array={merchants}
        next={next}
        prev={prev}
        setCount={setCount}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        setPaged={setPaged}
      />
    </div>
  );
}

export default AdminMerchants;
