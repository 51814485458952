import { createSlice } from "@reduxjs/toolkit";

const initialState = JSON.parse(localStorage.getItem("currentItem")) || {};
const slice = createSlice({
  name: "current item",
  initialState,
  reducers: {
    setCurrentItem: (state, { payload }) => {
      localStorage.setItem("currentItem", JSON.stringify(payload));
      return payload;
    },
  },
});
export const { setCurrentItem } = slice.actions;
export default slice.reducer;
