import { useDispatch } from "react-redux";
import { hideNotification, showNotification } from "../model/store/alert";

function useNotification(props) {
  let dispatch = useDispatch();
  /**
   *
   * @param {string} message - The message to be displayed
   * @param {boolean} isError - If the notification is a positive or negative notification
   */
  let handleNotification = (message, isError=false) => {
    dispatch(showNotification({ message, isError }));
    setTimeout(() => dispatch(hideNotification()), 3000);
  };
  return handleNotification;
}

export default useNotification;
