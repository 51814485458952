import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { apiVersion, OrderStatus } from "../../controller/api/apiCore";

function MerchantOrders(props) {
  const { merchant } = useSelector((state) => state);
  let [loading, setLoading] = useState(false);
  let [orders, setOrders] = useState([]);
  let [menuIndicator, setMenuIndicator] = useState(OrderStatus.Processing);
  const handleGetOrders = async (status) => {
    try {
      setLoading(true);
      let req = await fetch(`${apiVersion}/orders-merchant/${status}`, {
        headers: { Authorization: `Bearer ${merchant.token}` },
      });
      if (req.ok) {
        let res = await req.json();
        setOrders(res);
      }
      setLoading(false);
    } catch (error) {
      alert("could not fetch your orders,please try again");
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetOrders(OrderStatus.Processing);
  }, []);
  const handleOrderMenu = (menu) => {
    setMenuIndicator(menu);
    handleGetOrders(menu);
  };
  const handleChangeOrderStatus = async (order, status) => {
    let confirm = window.confirm("Are you sure? this action is irriversible");
    if (confirm)
      try {
        setLoading(true);
        let request = new Request(`${apiVersion}/order-status`, {
          method: "PATCH",
          headers: {
            Authorization: "Bearer " + merchant.token,
            "Content-type": "application/json",
          },
          body: JSON.stringify({ order, status: OrderStatus.Rejected }),
        });
        let req = await fetch(request);
        if (req.ok) {
          await handleGetOrders(menuIndicator);
        }
        setLoading(false);
      } catch (error) {
        alert("order could not be cancelled, please try again later");

        setLoading(false);
      }
  };
  return (
    <div className="merchant-order-body">
      {loading && (
        <div className="loader-con">
          <div className="loader"></div>
        </div>
      )}{" "}
      <div className="merchant-order-body__order-menu">
        <menu>
          {" "}
          <li>
            <button
              onClick={() => handleOrderMenu(OrderStatus.Processing)}
              className={
                menuIndicator === OrderStatus.Processing ? "active" : ""
              }
            >
              Pending
            </button>
          </li>
          <li>
            <button
              onClick={() => handleOrderMenu(OrderStatus.Confirmed)}
              className={
                menuIndicator === OrderStatus.Confirmed ? "active" : ""
              }
            >
              Confirmed
            </button>
          </li>
          <li>
            <button
              onClick={() => handleOrderMenu(OrderStatus.Shipped)}
              className={menuIndicator === OrderStatus.Shipped ? "active" : ""}
            >
              Shipped
            </button>
          </li>
          <li>
            <button
              onClick={() => handleOrderMenu(OrderStatus.Delivered)}
              className={
                menuIndicator === OrderStatus.Delivered ? "active" : ""
              }
            >
              Delivered
            </button>
          </li>
          <li>
            <button
              onClick={() => handleOrderMenu(OrderStatus.Cancelled)}
              className={
                menuIndicator === OrderStatus.Cancelled ? "active" : ""
              }
            >
              Cancelled
            </button>
          </li>
          <li>
            <button
              onClick={() => handleOrderMenu(OrderStatus.Returned)}
              className={menuIndicator === OrderStatus.Returned ? "active" : ""}
            >
              Returned
            </button>
          </li>
          <li>
            <button
              onClick={() => handleOrderMenu(OrderStatus.Rejected)}
              className={menuIndicator === OrderStatus.Rejected ? "active" : ""}
            >
              Rejected
            </button>
          </li>
        </menu>
      </div>
      <div className="table-con">
        <table>
          <thead>
            <tr>
              <th>Order#</th>
              <th>Status</th>
              <th>Name</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Total</th>
              <th>Date</th>
              {menuIndicator === OrderStatus.Processing && <th></th>}
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr>
                <td>{order.orderNumber?.toLocaleString()}</td>
                <td>
                  {order.status === OrderStatus.Processing
                    ? "PENDING"
                    : order.status}
                </td>
                <td>{order.name?.toLocaleString()}</td>
                <td>&#8358;{order.price?.toLocaleString()}</td>
                <td>{order.quantity?.toLocaleString()} units</td>
                <td>
                  &#8358;{(order.quantity * order.price).toLocaleString()}
                </td>
                <td>{moment(order.createdAt).format("D-MM-yy hh:mm")}</td>
                {menuIndicator === OrderStatus.Processing && (
                  <td>
                    <div>
                      <button
                        onClick={() =>
                          handleChangeOrderStatus(order, OrderStatus.Rejected)
                        }
                      >
                        Reject Order
                      </button>
                      <button
                        className="red"
                        onClick={() =>
                          handleChangeOrderStatus(order, OrderStatus.Confirmed)
                        }
                      >
                        Confirm Order
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MerchantOrders;
