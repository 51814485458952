import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as CloseSvg } from "../../res/images/close.svg";
import { ReactComponent as AddSvg } from "../../res/images/add.svg";
import {
  apiVersion,
  ProductsMode,
  ProductType,
} from "../../controller/api/apiCore";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Loader from "../reusables/Loader";
function MerchantProducts(props) {
  let limit = 100;
  let [page, setPage] = useState(0);
  let [newImages, setNewImages] = useState([]);
  let [images, setImages] = useState([]);
  let inputRef = useRef(null);
  let [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let [query, setQuery] = useState("-");
  let [mode, setMode] = useState("VERIFIED");
  let { merchant } = useSelector((state) => state);
  let [products, setProducts] = useState([]);
  let [selectedProduct, setSelectedProduct] = useState(null);

  const handleGetProducts = async (mode) => {
    try {
      setLoading(true);
      let request = await fetch(
        apiVersion + `/merchant-products/${limit}/${page}/${query}/${mode}`,
        { headers: { Authorization: "Bearer " + merchant.token } }
      );
      if (request.ok) {
        let res = await request.json();
        setProducts(res.products);
        setLoading(false);
        return;
      }
      throw new Error("Loading failed");
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleGetProducts(mode);
  }, []);

  const handleProductsMode = (mode) => {
    try {
      setMode(mode);
      handleGetProducts(mode);
    } catch (error) {
      alert("could not fetch products");
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let form = new FormData(e.target);
      form.append("productId", selectedProduct._id);
      form.append("images", JSON.stringify([...images, ...newImages]));
      try {
        let req = await fetch(`${apiVersion}/product-copy`, {
          method: "POST",
          body: form,
        });
        if (req.ok) {
          alert("Saved");
        }
      } catch (error) {
        alert(error.message);
      }
    } catch (error) {
      alert("Something went wrong, please try again later");
    }
  };
  const handleImages = (image) => {
    if (!images.includes((img) => img.image === image))
      setImages((state) => [...state, { image, action: "delete" }]);
  };
  const handleDisplayImage = (image) => {
    setImages((state) => [
      ...state.filter((img) => img.action !== "displayImage"),
      { image, action: "displayImage" },
    ]);
    setSelectedProduct((state) => {
      return { ...state, displayImage: image };
    });
  };
  
  return (
    <div className="merchant-products-body">
      {loading && <Loader />}
      {selectedProduct && (
        <form
          onSubmit={handleSubmit}
          className="merchant-products-body__edit-form"
        >
          <h3>{selectedProduct.name ?? selectedProduct.brand_name}</h3>
          <CloseSvg
            onClick={() => {
              setSelectedProduct(false);
            }}
            className="close"
          />
          <div className="merchant-products-body__edit-form__body">
            {selectedProduct.type.toLowerCase() ===
            ProductType.DRUG.toLowerCase() ? (
              <>
                <div className="merchant-products-body__edit-form__body__input-row">
                  <div className="merchant-products-body__edit-form__body__input-row__input-box">
                    <label htmlFor="">Brand Name</label>
                    <input
                      defaultValue={selectedProduct.brand_name}
                      name="brand_name"
                      type="text"
                    />
                  </div>
                  <div className="merchant-products-body__edit-form__body__input-row__input-box">
                    <label htmlFor="">Generic Name</label>
                    <input
                      defaultValue={selectedProduct.generic_name}
                      name="generic_name"
                      type="text"
                    />
                  </div>
                </div>
                <div className="merchant-products-body__edit-form__body__input-row">
                  <div className="merchant-products-body__edit-form__body__input-row__input-box">
                    <label htmlFor="">Strength</label>
                    <input
                      defaultValue={selectedProduct.strength}
                      name="strength"
                      type="text"
                    />
                  </div>
                  <div className="merchant-products-body__edit-form__body__input-row__input-box">
                    <label htmlFor="">Pack Size</label>
                    <input
                      defaultValue={selectedProduct.pack_size}
                      name="pack_size"
                      type="text"
                    />
                  </div>
                </div>
              </>
            ) : selectedProduct.type.toLowerCase() ===
              ProductType.CONSUMMABLE.toLowerCase() ? (
              <></>
            ) : (
              <></>
            )}
            <div className="merchant-products-body__edit-form__body__input-row">
              <div className="merchant-products-body__edit-form__body__input-row__input-box">
                <label htmlFor="">Quantity</label>
                <input
                  defaultValue={selectedProduct.quantity}
                  name="quantity"
                  type="number"
                />
              </div>
              <div className="merchant-products-body__edit-form__body__input-row__input-box">
                <label htmlFor="">Price</label>
                <input
                  defaultValue={selectedProduct.price}
                  name="price"
                  type="number"
                />
              </div>
            </div>
            <div className="merchant-products-body__edit-form__body__input-row">
              <div className="merchant-products-body__edit-form__body__input-row__input-box">
                <label htmlFor="">Manufacturer</label>
                <input
                  name="company"
                  defaultValue={selectedProduct.company}
                  type="text"
                />
              </div>
              <div className="merchant-products-body__edit-form__body__input-row__input-box">
                <label htmlFor="">Nafdac</label>
                <input
                  defaultValue={selectedProduct.nafdac}
                  name="nafdac"
                  type="text"
                />
              </div>
            </div>
            <div className="merchant-products-body__edit-form__body__input-row">
              <div className="merchant-products-body__edit-form__body__input-row__input-box">
                <label htmlFor="">Manufacturing Date</label>
                <input
                  defaultValue={moment(selectedProduct.manufacture_date).format(
                    "yyyy-MM-DD"
                  )}
                  name="manufacture_date"
                  type="date"
                />
              </div>
              <div className="merchant-products-body__edit-form__body__input-row__input-box">
                <label htmlFor="">Expiry Date</label>
                <input
                  defaultValue={moment(selectedProduct.expiry_date).format(
                    "yyyy-MM-DD"
                  )}
                  name="expiry_date"
                  type="date"
                />
              </div>
            </div>
            <label>Description</label>
            <textarea
              defaultValue={selectedProduct.description}
              name="description"
              id=""
              cols="30"
              rows="5"
            ></textarea>

            <div className="image-row ">
              {selectedProduct.images.map((image) => (
                <ImageBox
                  onClick={handleImages}
                  handleDisplayImage={handleDisplayImage}
                  isDisplayImage={selectedProduct.displayImage === image}
                  image={image}
                />
              ))}
              <div className="image-box add">
                {" "}
                <AddSvg />
                <small>Add Image</small>
                <input
                  ref={inputRef}
                  multiple={true}
                  onChange={(e) => {
                    var files = e.target.files;

                    for (let index = 0; index < files.length; index++) {
                      var reader = new FileReader();
                      reader.onload = function (event) {
                        if (
                          !newImages.includes(
                            (img) => img === event.target.result
                          )
                        )
                          setNewImages((state) => [
                            ...state,
                            { image: event.target.result, action: "newImage" },
                          ]);
                      };
                      const file = files[index];
                      reader.readAsDataURL(file);
                    }
                  }}
                  type="file"
                />
              </div>
              {newImages.map((image) => (
                <div className="image-box new">
                  {" "}
                  <CloseSvg
                    title="Remove picture"
                    onClick={() =>
                      setNewImages((state) =>
                        state.filter((img) => img.image !== image.image)
                      )
                    }
                  />
                  <img src={image.image} />
                </div>
              ))}
            </div>
            <button>Save Changes</button>
          </div>
        </form>
      )}
      <menu>
        <li>
          <button
            onClick={() => handleProductsMode(ProductsMode.VERIFIED)}
            className={mode === ProductsMode.VERIFIED ? "active" : ""}
          >
            Verified
          </button>
        </li>
        <li>
          <button
            onClick={() => handleProductsMode(ProductsMode.UNVERIFIED)}
            className={mode === ProductsMode.UNVERIFIED ? "active" : ""}
          >
            Unverified
          </button>
        </li>
        <li>
          <button
            onClick={() => handleProductsMode(ProductsMode.REJECTED)}
            className={mode === ProductsMode.REJECTED ? "active" : ""}
          >
            Rejected
          </button>
        </li>
        <li>
          <button
            onClick={() => handleProductsMode(ProductsMode.ARCHIVED)}
            className={mode === ProductsMode.ARCHIVED ? "active" : ""}
          >
            Archived
          </button>
        </li>
        <li>
          <button
            className={mode === "ADD" ? "active" : ""}
            onClick={() => {
              setMode("ADD");
              navigate("/merchant/add-item");
            }}
          >
            <i className="fa fa-plus"></i>
          </button>
        </li>
      </menu>
      <div className="table-con">
        <table>
          <thead>
            <tr>
              <th>|-|</th>
              <th>Name</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Added On</th>
              <th>isVisible</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr>
                <td>
                  <input type="checkbox" />
                </td>

                <td>
                  {product.name || product.brand_name || product.generic_name}
                </td>
                <td>{product.quantity?.toLocaleString()}</td>
                <td>&#8358;{product.price?.toLocaleString()}</td>
                <td>{moment(product.createdAt).format("D-MM-yy")}</td>
                <td>{product.isVisible?.toString()}</td>
                <td onClick={() => setSelectedProduct(product)}>edit</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MerchantProducts;
const ImageBox = ({ image, isDisplayImage, onClick, handleDisplayImage }) => {
  const [loaded, setLoaded] = useState(true);
  return (
    <div className={"image-box " + (loaded ? "loading" : "")}>
      {" "}
      <CloseSvg onClick={() => onClick(image)} />
      <img
        onLoad={() => {
          setLoaded(false);
        }}
        src={apiVersion + "/image/" + image}
      />
      {isDisplayImage ? (
        <small>Display Image</small>
      ) : (
        <button
          onClick={(e) => {
            e.preventDefault();
            handleDisplayImage(image);
          }}
          role="article"
        >
          Set Display Image
        </button>
      )}
    </div>
  );
};
