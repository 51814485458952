export const newItems = [
  {
    type: "Drug",
    brand_name: "Augmentin",
    generic: "Amoxicilline + Clavulanic acid",
    strength: "625mg",
    packsize: 14,
    quantity: 40,
    price: 3000,
    exp: "20 aug 2021",
  },
  {
    type: "Consumable",
    name: "Chenosic",
    category: "Carrier Bag",
    packsize: 100,
    quantity: 40,
    price: 100,
    exp: "20 aug 2021",
  },
  {
    type: "Drug",
    brand_name: "Imodium",
    generic: "Loperamide",
    strength: "4mg",
    packsize: 10,
    quantity: 100,
    price: 200,
    exp: "20 aug 2021",
  },
  {
    type: "Medical Equipment",
    name: "Omron classic",
    category: "BP appparatus",
    packsize: 1,
    quantity: 40,
    price: 11500,
    exp: "20 aug 2021",
  },
  {
    type: "Drug",
    brand_name: "Augmentin",
    generic: "Amoxicilline + Clavulanic acid",
    strength: "375mg",
    packsize: 14,
    quantity: 10,
    price: 2500,
    exp: "20 aug 2021",
  },
  {
    type: "Drug",
    brand_name: "Augmentin",
    generic: "Amoxicilline + Clavulanic acid",
    strength: "228.5mg/5ml",
    packsize: 14,
    quantity: 30,
    price: 1500,
    exp: "20 aug 2021",
  },
  {
    type: "Drug",
    brand_name: "Augmentin",
    generic: "Amoxicilline + Clavulanic acid",
    strength: "457mg/5ml",
    packsize: 14,
    quantity: 40,
    price: 1800,
    exp: "20 aug 2021",
  },
  {
    type: "Medical Equipment",
    name: "Maxom M",
    category: "Crutches",
    packsize: 1,
    quantity: 30,
    price: 7000,
    exp: "20 aug 2021",
  },
  {
    type: "Medical Equipment",
    name: "Maxom L",
    category: "Crutches",
    packsize: 1,
    quantity: 30,
    price: 8000,
    exp: "20 aug 2021",
  },
  {
    type: "Medical Equipment",
    name: "Maxom XL",
    category: "Crutches",
    packsize: 1,
    quantity: 30,
    price: 9000,
    exp: "20 aug 2021",
  },
  {
    type: "Medical Equipment",
    name: "Matix",
    category: "Sling",
    packsize: 1,
    quantity: 30,
    price: 6000,
    exp: "20 aug 2021",
  },
];

export const wishList = [
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
  {
    name: "Amlodipine 10mg",
    customers: [
      {
        name: "Ogunlesi Toyosi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Ayobami",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
      {
        name: "Obi Valentine",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maduka Stanley",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Maroh Paul",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Osayerie Anderson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Mike Balogun",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "James Smith",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Athele Nelson",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Amafor Owen",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: false,
      },
      {
        name: "Lazarus Kurotimi",
        dateAdded: Date.now().toString(),
        dateRemoved: Date.now().toString(),
        status: true,
      },
    ],
  },
];

export const reviews = [
  {
    name: "Xeloda clutches xl",
    "reviews&ratings": [
      { customer: "James", rating: 3, review: "" },
      {
        customer: "Jones",
        rating: 1,
        review: "poor quality, the packaging was in poor conditon",
      },
      { customer: "Yvette Idjogbe", rating: 5, review: "" },
      {
        customer: "Stanley maduka",
        rating: 4,
        review: "was good but delivery was slow",
      },
      { customer: "Brian Unknown", rating: 2, review: "" },
      { customer: "Nnamdi kalu", rating: 1, review: "" },
      { customer: "Jonathan", rating: 5, review: "love it" },
    ],
  },
  {
    name: "Andon ",
    "reviews&ratings": [
      { customer: "James", rating: 1, review: "" },
      {
        customer: "Jones",
        rating: 1,
        review: "poor quality, the packaging was in poor conditon",
      },
      { customer: "Yvette Idjogbe", rating: 1, review: "" },
      {
        customer: "Stanley maduka",
        rating: 2,
        review: "was good but delivery was slow",
      },
      { customer: "Brian Unknown", rating: 2, review: "" },
      { customer: "Nnamdi kalu", rating: 1, review: "" },
      { customer: "Jonathan", rating: 1, review: "love it" },
    ],
  },
  {
    name: "Accucheck Glucometer",
    "reviews&ratings": [
      { customer: "James", rating: 1, review: "" },
      {
        customer: "Jones",
        rating: 2,
        review: "poor quality, the packaging was in poor conditon",
      },
      { customer: "Yvette Idjogbe", rating: 5, review: "" },
      {
        customer: "Stanley maduka",
        rating: 5,
        review: "was good but delivery was slow",
      },
      { customer: "Brian Unknown", rating: 5, review: "hate it well" },
      { customer: "Nnamdi kalu", rating: 5, review: "" },
      { customer: "Jonathan", rating: 5, review: "love it" },
    ],
  },
  {
    name: "Biolumefar 80/480mg",
    "reviews&ratings": [
      { customer: "James", rating: 3, review: "" },
      {
        customer: "Jones",
        rating: 2,
        review: "poor quality, the packaging was in poor conditon",
      },
      { customer: "Yvette Idjogbe", rating: 5, review: "" },
      {
        customer: "Stanley maduka",
        rating: 4,
        review: "was good but delivery was  poor",
      },
      { customer: "Brian Unknown", rating: 2, review: "" },
      { customer: "Nnamdi kalu", rating: 5, review: "" },
      { customer: "Jonathan", rating: 5, review: "love it" },
    ],
  },
  {
    name: "Coartem 80/480",
    "reviews&ratings": [
      { customer: "James", rating: 3, review: "" },
      {
        customer: "Jones",
        rating: 1,
        review: "poor quality, the packaging was in poor conditon",
      },
      { customer: "Yvette Idjogbe", rating: 3, review: "" },
      {
        customer: "Stanley maduka",
        rating: 1,
        review: "was good but delivery was slow",
      },
      { customer: "Brian Unknown", rating: 2, review: "" },
      { customer: "Nnamdi kalu", rating: 3, review: "" },
    ],
  },
];

export const sales = [
  {
    name: "Augmentin 625",
    quantity: 10,
    unit_price: 1000,
    date: Date.now().toString(),
    buyer: "chukwu oghene",
    order_number: Date.now().toString(),
  },
  {
    name: "Amlovar",
    quantity: 14,
    unit_price: 2000,
    date: Date.now().toString(),
    buyer: "chukwu",
    order_number: Date.now().toString(),
  },
  {
    name: "artequick",
    quantity: 90,
    unit_price: 2000,
    date: Date.now().toString(),
    buyer: "Abasi",
    order_number: Date.now().toString(),
  },
  {
    name: "Cognitol",
    quantity: 79,
    unit_price: 4000,
    date: Date.now().toString(),
    buyer: "Eze",
    order_number: Date.now().toString(),
  },
];

export const orders = [
  {
    date_ordered: Date.now().toString(),
    date_returned: Date.now().toString(),
    order_number: Date.now().toString(),
    orders: [
      {
        buyer: "James Jones",
        name: "Complan",
        price: 48920,
        quantity: 10,
        status: true,
      },
      {
        buyer: "Onas",
        name: "5ml syringe",
        price: 10000,
        quantity: 100,
        status: false,
      },
      {
        buyer: "Stanley & sons",
        name: "Kiss Condom",
        price: 50000,
        quantity: 5000,
        status: true,
      },
    ],
  },
  {
    date_ordered: Date.now().toString(),
    date_returned: Date.now().toString(),
    order_number: Date.now().toString(),
    orders: [
      {
        buyer: "Jones",
        name: "Dynamogen",
        price: 15000,
        quantity: 10,
        status: true,
      },
      {
        buyer: "Brian ltd",
        name: "Samsu oil",
        price: 20000,
        quantity: 30,
        status: true,
      },
      {
        buyer: "D'sanitas Pharmacy",
        name: "Daflon",
        price: 30000,
        quantity: 5,
        status: true,
      },
    ],
  },
];
