import React, { useEffect, useRef, useState } from "react";
import { apiVersion, ProductType } from "../../../controller/api/apiCore";
import "../../admin/edit_product.scss";
import Loader from "../../reusables/Loader";
import { ReactComponent as DeleteIcon } from "../../../res/images/cancel.svg";
import { useSelector } from "react-redux";
import useNotification from "../../../hooks/useNotification";
function AddProduct(props) {
  const notify = useNotification();
  const { token } = useSelector((state) => state.user);
  const [type, setType] = useState("");
  const [types, setTypes] = useState([]);
  const [addedImages, setAddedImages] = useState([]);
  const [formulations, setFormulations] = useState([]);
  const [routeOfAdmins, setRouteOfAdmin] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const[isSuccessful,setIsSuccessful] = useState(false)
  const [loading, SetLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const form = useRef(null);
  const handleGetFormulation = async (id) => {
    setIsDisabled(true);
    try {
      let req = await fetch(apiVersion + "/formulation/" + id);
      if (req.ok) {
        setFormulations(await req.json());
      }
      setIsDisabled(false);
    } catch (error) {
      console.log(error);
    }
  };

  //get route of administration
  const handleGetROA = async () => {
    setIsDisabled(true);
    try {
      let req = await fetch(apiVersion + "/roa");
      if (req.ok) {
        setRouteOfAdmin(await req.json());
      }
      setIsDisabled(false);
    } catch (error) {}
  };
  const getCategory = async (type) => {
    setIsDisabled(true);
    try {
      let req = await fetch(apiVersion + "/category/" + type);
      if (req.ok) {
        setCategories(await req.json());
      }
      setIsDisabled(false);
    } catch (error) {}
  };
  const getCompanies = async (type) => {
    try {
      setIsDisabled(true);
      let req = await fetch(apiVersion + "/brands");
      if (req.ok) {
        setCompanies(await req.json());
      }
      setIsDisabled(false);
    } catch (error) {}
  };
  const getTypes = async (type) => {
    try {
      let req = await fetch(apiVersion + "/types");
      if (req.ok) {
        let types = await req.json();
        setTypes(types);
        setType(types[0].name);
        //  handleGetFormulation(types[0]);
        await getCategory(types[0].name);
      }
    } catch (error) {}
  };

  const handleRoaChange = (e) => {
    console.log(e.target.value0);
    handleGetFormulation(e.target.value);
  };

  const handleTypeChange = (e) => {
    let type = e.target.value;
    getCategory(type);
    setType(type);
  };

  useEffect(() => {
    (async () => {
      await getTypes();
      await getCompanies();
      await handleGetROA();
      // await handleGetFormulation();
      SetLoading(false);
    })();
  }, []);
  const handleSubmit = async (event) => {
    setIsDisabled(true);
    event.preventDefault();
    let form = event.target;

    let formData = new FormData(event.target);
    try {
      let req = await fetch(apiVersion + "/pending_item", {
        body: formData,
        method: "post",
        headers: { Authorization: "Bearer " + token },
      });
      if (req.ok) {
        let { brand_name, name } = await req.json();
        notify((brand_name ?? name) + " successfully sent for review", false);
        form.reset();
        // localStorage.setItem("formState", JSON.stringify(defaultForm));
        form.type.focus();
        setAddedImages([]);
      } else throw new Error("failed");
    } catch (error) {
      notify("Post failed, please try again later", true);
    }
    setIsDisabled(false);
  };
  const handleSubmitFile = async (e) => {
    try {
      e.preventDefault();
      setIsDisabled(true)
      let formData = new FormData(e.target);
      let req = await fetch(apiVersion + "/pending_items", {
        method: "POST",
        body: formData,
        headers: { Authorization: "Bearer " + token },
      });
      if (req.ok) {
        setIsDisabled(false)
        alert("sent");
      }
    } catch (error) {
      alert(error);
    }
    setIsDisabled(false)
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="edit-product-con">
        <form onSubmit={handleSubmitFile} enctype="multipart/form-data">
          <input
            type="file"
            name="excel_file"
            accept=".xlsx"
            multiple="false"
          />
          <button>Send</button>
        </form>
      </div>
      <div className="edit-product-con">
        {" "}
        <h2>{"Add New Product"}</h2>
        <form
          ref={form}
          onSubmit={handleSubmit}
          className="edit-product-con__body"
        >
          <DetailForm title={"Type"} info="The Type of product">
            <select
              onChange={handleTypeChange}
              defaultValue={type ?? ""}
              name="type"
            >
              {types.map((type) => (
                <option value={type.name} key={type._id}>
                  {type.name}
                </option>
              ))}
            </select>
          </DetailForm>
          <DetailForm
            title={"Category"}
            info="The category the product belongs to"
          >
            <select
              name="primary_category"
              //   defaultValue={product?.primary_category ?? ""}
              disabled={isDisabled}
            >
              <option value="" disabled="true" selected={true}>
                Select Category
              </option>
              {categories.map((category) => (
                <option value={category.name} key={category._id}>
                  {" "}
                  {category.name}
                </option>
              ))}
            </select>
          </DetailForm>
          {type === ProductType.DRUG ? (
            <>
              {" "}
              <DetailForm
                title={"ROA"}
                info="The route of administration of the drug, e.g Oral, Sublingual e.t.c."
              >
                <select
                  onChange={handleRoaChange}
                  // defaultValue={product?.roa ?? ""}
                  name="route_of_administration"
                  disabled={isDisabled}
                >
                  {routeOfAdmins.map((roa) => (
                    <option key={roa._id} value={roa.name}>
                      {roa.name}
                    </option>
                  ))}
                </select>
              </DetailForm>
              <DetailForm title={"Formulation"} info="The name of the product">
                <select
                  type="text"
                  //   defaultValue={product?.formulation ?? ""}
                  name="formulation"
                  disabled={isDisabled}
                >
                  {formulations.map((form) => (
                    <option value={form.name} key={form._id}>
                      {form.name}
                    </option>
                  ))}
                </select>
              </DetailForm>
              <DetailForm
                title={"Brand Name"}
                info="The brand name of the product e.g Panadol, Eusadol, Tumol e.t.c"
              >
                <input
                  type="text"
                  name="brand_name"
                  //    defaultValue={product?.brand_name ?? ""}
                />
              </DetailForm>
              <DetailForm
                title={"Generic Name"}
                info="The generic name of the product e.g Paracetamol, Acetaminophen"
              >
                <input
                  type="text"
                  name="generic_name"
                  //  defaultValue={product?.generic_name ?? ""}
                />
              </DetailForm>
              <DetailForm
                title={"Strength"}
                info="The strength of the drug e.g 30mg, 100mls e.t.c"
              >
                <input
                  type="text"
                  name="strength"
                  //  defaultValue={product?.strength ?? ""}
                />
              </DetailForm>
              <DetailForm
                title={"Packet Size"}
                info="The amount of items in a packet of drug"
              >
                <input
                  type="text"
                  name="pack_size"
                  // defaultValue={product?.pack_size ?? ""}
                />
              </DetailForm>
            </>
          ) : (
            <DetailForm
              title={"Name"}
              info="The name of the product e.g 5ml syring, Omron machine e.t.c"
            >
              <input
                type="text"
                name="name"
                //    defaultValue={product?.brand_name ?? ""}
              />
            </DetailForm>
          )}
          <DetailForm
            title={"Quantity"}
            info="The number of units of the product"
          >
            <input
              type="number"
              step={2}
              name="quantity"
              //  defaultValue={product?.quantity ?? ""}
            />
          </DetailForm>
          <DetailForm title={"Price"} info="The price of the product">
            <input
              type="number"
              step={2}
              name="price"
              //  defaultValue={product?.price ?? ""}
            />
          </DetailForm>
          <DetailForm
            title={"Company"}
            info="The name of the manufacturing company"
            disabled={isDisabled}
          >
            <select name="company">
              {companies.map((company) => (
                <option key={company._id} value={company.name}>
                  {company.name}
                </option>
              ))}
            </select>
          </DetailForm>
          <DetailForm title={"Nafdac Number"} info="The name of the product">
            <input type="text" name="nafdac" />
          </DetailForm>
          <DetailForm
            title={"Mfd Date"}
            info="The date the drug was manufactured"
          >
            <input type="date" name="manufacture_date" />
          </DetailForm>
          <DetailForm title={"Exp Date"} info="The expiration date of the drug">
            <input type="date" name="expiry_date" />
          </DetailForm>
          <DetailForm
            title={"Description"}
            info="The Description of the product"
          >
            <textarea name="description" rows={5} />
          </DetailForm>

          <DetailForm title={"Add Image"} info={"Add image to product"}>
            <div className="edit-product-con__body__images">
              <div className="edit-product-con__body__images__image-card">
                <input
                  multiple={true}
                  onChange={(e) => {
                    setAddedImages([]);
                    for (let i = 0; i < e.target.files.length; i++) {
                      const url = URL.createObjectURL(e.target.files[i]);
                      setAddedImages((state) => [...state, url]);
                    }
                  }}
                  type="file"
                  name="image_files"
                  accept=".jpeg,.jpg,.png"
                />
              </div>
              {addedImages.map((image) => (
                <div className="edit-product-con__body__images__image-card">
                  <DeleteIcon
                    onClick={() =>
                      setAddedImages((state) =>
                        state.filter((img) => img !== image)
                      )
                    }
                  />
                  <img src={image} alt="" />
                  <p>{image}</p>
                </div>
              ))}
            </div>
          </DetailForm>
          <DetailForm
            title="Search Tags"
            info={
              "words to aid search for this product, separate each tag with a comma, eg,antacid,anti-flatulence,anti-constipation"
            }
          >
            <input type="text" name="tags" />
          </DetailForm>
          <button disabled={isDisabled} type="submit">Save</button>
        </form>
      </div>
    </>
  );
}

const DetailForm = ({ title, info, name, type = "text", children }) => (
  <div className="detail-form">
    <label>{title}</label>
    <div className="detail-form__body">
      {children}
      <small>{info}</small>
    </div>
  </div>
);

const ImageCard = ({ src, handleDeleteImage }) => {
  return (
    <div className="edit-product-con__body__images__image-card">
      <DeleteIcon onClick={handleDeleteImage} />
      <img src={src} alt="" />
      <p>{src}</p>
    </div>
  );
};

export default AddProduct;
