import { createSlice } from "@reduxjs/toolkit";
let rawState = localStorage.getItem("merchant");
let initialState = rawState ? JSON.parse(rawState) : {};

let merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    setMerchant: (state, { payload }) => {
      localStorage.setItem("merchant", JSON.stringify(payload));
      return payload;
    },
    setOrders: (state, payload) => {},
    setVerifiedProducts: () => {},
    setUnverifiedProducts: () => {},
  },
});

export let {
  setMerchant,
  setOrders,
  setVerifiedProducts,
  setUnverifiedProducts,
} = merchantSlice.actions;

export default merchantSlice.reducer;
