import { apiVersion } from "./api/apiCore";
//add to cart
export const addToCartApi = async (product, token) => {
  try {
    console.log(product);
    const req = await fetch(apiVersion + "/cart", {
      method: "POST",
      body: JSON.stringify(product),
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    });
    if (req.ok) {
      const res = await req.json();
    }
  } catch (error) {
    alert("oops something went wrong, product could not be added too cart");
  }
};
export const updateCart = async (product, token) => {
  try {
    const req = await fetch(apiVersion + "/cart", {
      method: "PATCH",
      body: JSON.stringify(product),
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    });
  } catch (error) {
    alert("oops something went wrong, product could not be added too cart");
  }
};

export const deleteFromCart = async (id, token) => {
  try {
    const req = await fetch(apiVersion + "/cart", {
      method: "DELETE",
      body: JSON.stringify(id),
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    });
    if (req.ok) {
      const res = await req.json();
    }
  } catch (error) {
    alert("oops something went wrong, product could not be added too cart");
  }
};
