import React, { useEffect, useState } from "react";
import { newItems } from "../../../../../model/newItems";
import PaginationHead from "../../../reusables/PaginationHead";
import PaginationPages from "../../../reusables/PaginationPages";
import Table from "./components/Table";
import { useNavigate, useParams } from "react-router-dom";
import { apiVersion } from "../../../../../controller/api/apiCore";

function Stock(props) {
  const { id } = useParams();
  const [products, setProducts] = useState([...props.products]);
  const [paged, setPaged] = useState([...props.products.slice(0, 10)]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setCount] = useState({ prev: 0, next: 10 });
  const { prev, next } = pageCount;
  const navigate = useNavigate();
  useEffect(() => {}, []);

  const handleToEdit = (product) => {
    navigate("/admin/edit-product/" + product._id + "/approvedProduct");
  };
  const handleDeleteProduct = async (e, product) => {
    e.stopPropagation();
    try {
      let shouldDelete = window.confirm(
        "Are you sure you want to delete " + product.name
      );
      if (shouldDelete) {
        let req = await fetch(apiVersion + "/product/" + product._id, {
          method: "DELETE",
        });
        if (req.ok) {
          alert("Product deleted successfully");
          setPaged((state) => state.filter(({ _id }) => _id !== product._id));
        }
      }
    } catch (error) {
      alert(error);
    }
  };
  return (
    <>
      <PaginationHead
        array={products}
        next={next}
        prev={prev}
        setPageIndex={setPageIndex}
        setPaged={setPaged}
        setCount={setCount}
      />

      <Table
        paged={paged}
        handleDeleteProduct={handleDeleteProduct}
        handleToEdit={handleToEdit}
        prev={prev}
      />
      <PaginationPages
        array={products}
        next={next}
        prev={prev}
        setCount={setCount}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        setPaged={setPaged}
      />
    </>
  );
}

export default Stock;
