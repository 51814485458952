import React, { useState } from "react";

function Input({ type, name, label, id, value, placeholder, required }) {
  // const [inputValue, setInputValue] = useState(value);
  // const handleInputValue = (e) => setInputValue(e.target.value);
  return (
    <div className="input-con">
      <label>{label}</label>
      <input
        type={type}
        defaultValue={value}
        name={name}
        id={id}
        placeholder={placeholder}
        required={required ?? false}
      />
    </div>
  );
}

export default Input;
