import { createSlice } from "@reduxjs/toolkit";
import {
  addToCartApi,
  deleteFromCart,
  updateCart,
} from "../../controller/cartHandler";
//export const bugAdded = createAction("bugAdded");
//export const bugRemoved = createAction("bugRemoved");
//export const bugResolved = createAction("bugResolved");

//action types

//Action Creators

const cart = JSON.parse(localStorage.getItem("cart"))
  ? JSON.parse(localStorage.getItem("cart"))
  : [];

//using create slice
const slice = createSlice({
  name: "cart",
  initialState: cart,
  reducers: {
    setCart: (_, { payload }) => {
      localStorage.setItem("cart", JSON.stringify(payload));
      return payload;
    },
    addItem: (state, { payload }) => {
      if (!state.find(({ _id }) => _id === payload.item._id)) {
        state.push(payload.item);
        addToCartApi(payload.item, payload.token);
      } else if (
        state[state.findIndex(({ _id }) => _id === payload.item._id)]
          .quantity !== payload.item.quantity
      ) {
        state[state.findIndex(({ _id }) => _id === payload.item._id)].quantity =
          payload.item.quantity;
        updateCart(payload.item, payload.token);
      }
      localStorage.setItem("cart", JSON.stringify(state));
      return state;
    },

    removeItem: (state, { payload }) => {
      state = state.filter(({ _id }) => _id !== payload.id);
      localStorage.setItem("cart", JSON.stringify(state));
      deleteFromCart({ _id: payload.id }, payload.token);
      return state;
    },
    increaseQuantity: (state, { payload }) => {
      let item = state.find(({ _id }) => _id === payload.id);
      payload.quantity ? (item.quantity = payload.quantity) : ++item.quantity;
      state[state.findIndex(({ _id }) => _id === item._id)] = item;
      updateCart(item, payload.token);
      localStorage.setItem("cart", JSON.stringify(state));
      return state;
    },
    decreaseQuantity: (state, { payload }) => {
      let item = state.find(({ _id }) => _id === payload.id);
      --item.quantity;
      state[state.findIndex(({ _id }) => _id === item._id)] = item;
      updateCart(item, payload.token);
      localStorage.setItem("cart", JSON.stringify(state));
      return state;
    },
  },
});

export const {
  setCart,
  addItem,
  removeItem,
  increaseQuantity,
  decreaseQuantity,
} = slice.actions;
export default slice.reducer;
