import React, { useEffect, useState } from "react";
import { createRipple } from "../../../../../controller/ripple";
import { apiVersion } from "../../../../../controller/api/apiCore";
import PaginationHead from "../../../reusables/PaginationHead";
import PaginationPages from "../../../reusables/PaginationPages";
import EditItem from "./components/EditItem";
import Table from "./components/Table";
import { useSelector } from "react-redux";
import useNotification from "../../../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";

function NewItems(props) {
  const [merchants, setMerchants] = useState([...props.pending_products]);
  const [paged, setPaged] = useState([...props.pending_products.slice(0, 10)]);
  const [item, setItem] = useState([]);
  const [labels, setLabels] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setCount] = useState({ prev: 0, next: 10 });
  const navigate = useNavigate();
  const { prev, next } = pageCount;
  const notification = useNotification();
  let { token } = useSelector((state) => state.user);

  useEffect(() => {
    //setMerchants(newItems);
    //setPaged(newItems.slice(0, 10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log(props);
  }, []);

  const handleEdit = (e, item) => {
    //  delete item.merchant_id;
    //delete item.merchant_name;
    // delete item._id;
    // delete item.createdAt;
    // delete item.updatedAt;
    // delete item.__v;
    // delete item.manufacture_date;
    // delete item.expiry_date;
    //  delete item.images;
    if (!labels.length > 0) {
      setLabels(Object.keys(item).sort());
      setItem(item);
      e.stopPropagation();
    }
    navigate("/admin/edit-product/" + item._id + "/newProduct");
  };
  var closeEdit = () => {
    setLabels([]);
    setItem({});
  };
  window.onclick = (e) => {
    !e.target.closest(".edit-con") && closeEdit();
  };
  const handleSubmit = async (e) => {
    createRipple(e);
    e.preventDefault();
    e.stopPropagation();
    let form = new FormData(e.target);
    form.append("images", item.images);
    form.append("merchant_id", item.merchant_id);
    form.append("merchant_name", item.merchant_name);
    form.append("manufacture_date", item.manufacture_date);
    form.append("expiry_date", item.expiry_date);
    try {
      let req = await fetch(apiVersion + "/item", {
        method: "Post",
        body: form,
        headers: { Authorization: "Bearer " + token },
      });
      if (req.ok) {
        notification("Item Saved to public directory");
        let res = await req.json();
        setPaged((prev) => prev.filter(({ _id }) => _id !== item._id));
        closeEdit();
        await fetch(apiVersion + "/pending_item?id=" + item._id, {
          method: "DELETE",
        });
      }
    } catch (error) {}
  };

  return (
    <>
      <EditItem
        labels={labels}
        onSubmit={handleSubmit}
        item={item}
        isEdit={labels.length > 0}
      />

      <PaginationHead
        array={merchants}
        next={next}
        prev={prev}
        setPageIndex={setPageIndex}
        setPaged={setPaged}
        setCount={setCount}
      />
      <Table paged={paged} prev={prev} handleEdit={handleEdit} />

      <PaginationPages
        array={merchants}
        next={next}
        prev={prev}
        setCount={setCount}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        setPaged={setPaged}
      />
    </>
  );
}

export default NewItems;
