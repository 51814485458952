import React from "react";
import { useSelector } from "react-redux";
import "../../res/styles/alert.scss";
import errorIcon from "../../res/images/error.svg";
import goodIcon from "../../res/images/tick.svg";
function AlertBox(props) {
  const state = useSelector((state) => state.alert);

  return (
    <div
      className={
        "alert-parent " +
        (state.isVisible ? "down " : "up ") +
        (state.isError && "error")
      }
    >
      <p>{state.message}</p>
      {state.isError ? (
        <img className="" src={errorIcon} alt="error" />
      ) : (
        <img className="" src={goodIcon} alt="good" />
      )}
    </div>
  );
}

export default AlertBox;
