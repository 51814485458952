import React from "react";

function Table({ paged, onClick, prev }) {
  return (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Wishlist</th>
          <th>Cart</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {paged.map((customer, index) => (
          <tr
            onClick={() => onClick(customer._id)}
            key={customer.name + index + customer["reviews&rating"]}
          >
            <td>{index + prev + 1}</td>
            <td>{customer?.business_name}</td>
            <td>{customer?.wishlist?.length}</td>
            <td>{customer?.cart?.length}</td>
            <td>{customer?.is_verified ? "Verified" : "Unverified"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
