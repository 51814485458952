import React, { useEffect, useRef, useState } from "react";
import NewItems from "./new items/NewItems";
import ReviewsRatings from "./reviews and ratings/ReviewsRatings";
import Sales from "./sales/Sales";
import Stock from "./stock/Stock";
import MerchantDetailsCard from "./components/MerchantDetailsCard";
import UserSvg from "../../components/UserSvg";
import Wishlist from "./wishlist/Wishlist";
import Orders from "./orders/Orders";
import { useParams } from "react-router-dom";
import apiCore, { apiVersion } from "../../../../controller/api/apiCore";
import Loader from "../../../reusables/Loader";
function AdminMerchantAccount(props) {
  const [state, setState] = useState({
    isClicked: false,
    stock: false,
    orders: false,
    reviews: false,
    sales: false,
    new: false,
    wishlist: false,
  });
  const [title, setTitle] = useState("");
  const { id } = useParams();
  const [merchant, setMerchant] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleClick = (key, title) => {
    return state.isClicked
      ? setState((prevState) => {
          let state = { ...prevState };
          state.isClicked = false;
          return state;
        })
      : setState((prevState) => {
          let state = {};
          state.isClicked = true;
          state[key] = true;
          setTitle(title);
          return state;
        });
  };
  const myRef = useRef();
  const getMerchant = async (id) => {
    try {
      let req = await fetch(apiVersion + "/merchant/" + id);
      if (req.ok) {
        let res = await req.json();
        console.log(res);
        setMerchant(res);
      }
    } catch (error) {
      alert("could not get merchant");
    }
    setLoading(false);
  };
  useEffect(() => {
    getMerchant(id);
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <div ref={myRef} className="admin-merchant-profile-con">
      <div className="admin-merchant-details-con">
        <p className="business-name">{merchant?.business_name}</p>
        <p className="merchant-name">{merchant?.fullname}</p>
        <p className="merchant-name">{merchant?.email}</p>
        <p className="merchant-address">{merchant?.address}</p>
      </div>
      <div
        style={{ left: state.isClicked && "-150%" }}
        className="admin-merchant-options-con"
      >
        <MerchantDetailsCard
          onClicked={() => handleClick("new", "New Items")}
          title="New Items"
          count={merchant?.pending_products.length}
        />
        <MerchantDetailsCard
          onClicked={() => handleClick("stock", "Stock")}
          title="Products"
          count={merchant?.products?.length}
        />
        <MerchantDetailsCard
          onClicked={() => handleClick("orders", "Orders")}
          title="Orders"
          count={merchant?.orders.length}
        />
        <MerchantDetailsCard
          onClicked={() => handleClick("reviews", "Reviews & Ratings")}
          title="Reviews & Ratings"
          count={10}
        />
        <MerchantDetailsCard
          onClicked={() => handleClick("sales", "Sales")}
          title="Sales"
          count={"\u20A6100k"}
        />
        <MerchantDetailsCard
          onClicked={() => handleClick("wishlist", "Wish List")}
          title="Wish List"
          count={200}
        />
      </div>
      <div
        style={{ right: state.isClicked ? "0%" : "-150%" }}
        className="chosen-vehicle"
      >
        <p className="option-title">
          <span onClick={handleClick} className="option-back">
            {" "}
            <UserSvg caret={true} />
          </span>{" "}
          {title}
        </p>
        {state.orders && <Orders />}
        {state.new && (
          <NewItems pending_products={merchant?.pending_products} />
        )}
        {state.stock && <Stock products={merchant?.products} />}
        {state.sales && <Sales />}
        {state.reviews && <ReviewsRatings />}
        {state.wishlist && <Wishlist />}
      </div>
    </div>
  );
}

export default AdminMerchantAccount;
