import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiVersion } from "../../controller/api/apiCore";
import "../../res/styles/login.scss";
const SignUp = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const passwordRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let body = new FormData(e.target);
      let req = await fetch(apiVersion + "/merchant", { method: "POST", body });
      if (req.ok) {
        navigate("/");
      }
    } catch (error) {
      alert("Sign up failed");
    }
    setIsLoading(false);
  };
  return (
    <div className={"form_con "}>
      <h2>Add Merchant</h2>
      <form onSubmit={handleSubmit} className="form">
        <div className="input_container">
          <label className="label">First Name</label>
          <input type="text" name="firstname" required className="input" />
        </div>
        <div className="input_container">
          <label className="label">Last Name</label>
          <input className="input" name="lastname" type="text" required />
        </div>
        {/* <div className="input_container">
          <label>Date Of Birth</label>
          <input className="input" name="date_of_birth" type="date" required />
        </div>
        <div className="input_container">
          <label htmlFor="">Sex</label>
          <select name="sex">
            <option selected={true} disabled="true">
              Select Sex
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div> */}
        <div className="input_container">
          <label className="label">Company Name</label>
          <input className="input" name="business_name" type="text" required />
        </div>
        <div className="input_container">
          <label className="label">Address</label>
          <input className="input" name="address" type="text" required />
        </div>
        <div className="input_container">
          <label className="label">Phone Number</label>
          <input
            className="input"
            name="phone_number"
            inputMode="tel"
            required
          />
        </div>
        <div className="input_container">
          <label className="label">Email Address</label>
          <input className="input" name="email" inputMode="email" required />
        </div>
        <div className="input_container">
          <label className="label">Registration Number</label>
          <input
            className="input"
            name="registration_number"
            inputMode="numeric"
            placeholder="PCN Registration number"
          />
        </div>
        {/* <div className="input_container">
          <label className="label">CAC Number</label>
          <input
            className="input"
            name="cac_number"
            inputMode="numeric"
            required
          />
        </div> */}
        <div className="input_container">
          <label className="label">Password</label>
          <input
            ref={passwordRef}
            className="input"
            name="password"
            type="password"
            required
          />
        </div>
        <div className="input_container">
          <label className="label">Confirm Password</label>
          <input
            onBlur={(e) => {
              if (e.target.value !== passwordRef.current.value) {
                alert("Password does not match");
                passwordRef.current.clear();
                e.target.value = "";
              }
            }}
            className="input"
            name=""
            type="password"
            required
          />
        </div>
        <input
          type="submit"
          disabled={isLoading}
          className="login_btn"
          value={isLoading ? "Please Wait..." : "Sign Up"}
        />{" "}
        <div className="forgot_password">
          <Link to="/">Already have an account? Log In</Link>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
