import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { apiVersion } from "../../controller/api/apiCore";

function MerchantDashboard(props) {
  let merchant = useSelector((state) => state.merchant);
  let [summaryState, setSummaryState] = useState({
    archivedProducts: 0,
    cancelledOrders: 0,
    deliveredOrders: 0,
    pendingOrders: 0,
    rejectedOrders: 0,
    rejectedProducts: 0,
    returnedOrders: 0,
    shippedOrders: 0,
    unverifiedProducts: 0,
    verifiedProducts: 0,
  });
  const handleDashboard = async () => {
    try {
      let req = await fetch(apiVersion + "/merchant-dashboard", {
        headers: { Authorization: "Bearer " + merchant.token },
      });
      if (req.ok) {
        let res = await req.json();
        console.log(res);
        setSummaryState(res);
      }
    } catch (error) {
      alert("Could o fetch summary");
      console.log(error);
    }
  };
  useEffect(() => {
    console.log(summaryState);
    handleDashboard();
  }, []);
  return (
    <div className="merchant-dashboard-body">
      <div className="merchant-dashboard-body__card">
        <h4>Product Summary</h4>
        <div className="info-block">
          <p>Verified Products</p>
          <p>{summaryState.verifiedProducts}</p>
        </div>
        <div className="info-block">
          <p>Unverified Products</p>
          <p>{summaryState.unverifiedProducts}</p>
        </div>
        <div className="info-block">
          <p>Archived Products</p>
          <p>{summaryState.archivedProducts}</p>
        </div>
        <div className="info-block">
          <p>Rejected Products</p>
          <p>{summaryState.rejectedProducts}</p>
        </div>
      </div>
      <div className="merchant-dashboard-body__card">
        <h4>Orders Summary</h4>
        <div className="info-block">
          <p>Pending Orders</p>
          <p>{summaryState.pendingOrders}</p>
        </div>
        <div className="info-block">
          <p>Shipped Orders</p>
          <p>{summaryState.shippedOrders}</p>
        </div>
        <div className="info-block">
          <p>Fulfilled Orders</p>
          <p>{summaryState.deliveredOrders}</p>
        </div>
        <div className="info-block">
          <p>Cancelled Orders</p>
          <p>{summaryState.cancelledOrders}</p>
        </div>
      </div>
    </div>
  );
}

export default MerchantDashboard;
