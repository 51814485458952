import React, { useState } from "react";
import { ProductType } from "../../../controller/api/apiCore";

function Select({
  label,
  name,
  value,
  options,
  group,
  external,
  selectFormulation,
  onChange,
}) {
  const [selectState, setSelectState] = useState(value);
  const handleSelect = ({ target }) => {
    const { value } = target;
    if (onChange) {
      switch (value) {
        case ProductType.CONSUMABLE:
          onChange(1, value);
          break;
        case ProductType.MEDICAL_DEVICE:
          onChange(2, value);
          break;
        case ProductType.MEDICAL_EQUIPMENT:
          onChange(3, value);
          break;
        case ProductType.DRUG:
          onChange(4, value);
          break;

        default:
          onChange();
          break;
      }
    }

    if (selectFormulation) {
      selectFormulation(value);
    }
  };
  return (
    <div className="input-con">
      <label>{label}</label>
      <select onChange={handleSelect} name={name}>
        {group && (
          <>
            {options?.map((option) => (
              <option
                key={option.option ?? option._id}
                value={option.value ?? option.name}
              >
                {option.option ?? option.name}
              </option>
            ))}
          </>
        )}

        {!group &&
          options?.map((option) => (
            <option
              key={option.option ?? option._id}
              value={option.value ?? option.name}
            >
              {option.option ?? option.name}
            </option>
          ))}
      </select>
    </div>
  );
}

export default Select;
