import React, { useEffect, useState } from "react";
import PaginationHead from "../reusables/PaginationHead";
import PaginationPages from "../reusables/PaginationPages";
import Table from "./components/Table";
import { apiVersion } from "../../../controller/api/apiCore";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../reusables/Loader";

function AdminCustomers(props) {
  let user = useSelector((state) => state.user);
  let navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setCount] = useState({ prev: 0, next: 1 });
  const { prev, next } = pageCount;
  const [customers, setCustomers] = useState([]);
  const [paged, setPaged] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    handleGetCustomers();
    setPaged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleSearch = async ({ target }) => {
  //   let query = new RegExp(target.value, "i");
  //   const filtered = rawMerchants.filter(({ bn }) => bn.match(query));
  //   await setCustomers(filtered);
  //   await setPaged(filtered.slice(0, 10));
  // };

  const handleToCustomer = (id) => {
    navigate("/admin/customer/" + id);
  };
  const handleGetCustomers = async () => {
    setLoading(true);
    let req = await fetch(apiVersion + "/customers/" + pageIndex, {
      method: "GET",
      headers: { Authorization: "Bearer " + user.token },
    });
    if (req.ok) {
      setCustomers(await req.json());
    }
    setLoading(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="admin-merchant-parent">
      {/* <SortBar
        handleSearch={handleSearch}
        menu={showMenu}
        onSort={{ handleSortMenu, handleShowMenu }}
      /> */}

      <PaginationHead
        array={customers}
        next={next}
        prev={prev}
        setPageIndex={setPageIndex}
        setPaged={setPaged}
        setCount={setCount}
      />
      <Table paged={customers} prev={prev} onClick={handleToCustomer} />
      <PaginationPages
        array={customers}
        next={next}
        prev={prev}
        setCount={setCount}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        setPaged={setPaged}
      />
    </div>
  );
}

export default AdminCustomers;
