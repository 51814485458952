import axios from "axios";
export default axios.create({
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
});
let devApi = "/api/v1";
let renderApi = "https://dmart-server.onrender.com/api/v1";
let herokuApi = "https://drugmart-tsx-server.herokuapp.com/api/v1";
export const apiVersion = renderApi;

export const OrderStatus = {
  Processing: "PROCESSING",
  Shipped: "SHIPPED",
  Delivered: "DELIVERED",
  Cancelled: "CANCELLED",
  Returned: "RETURNED",
  Rejected: "REJECTED",
  Confirmed: "CONFIRMED",
};

export const ProductsMode = {
  VERIFIED: "VERIFIED",
  UNVERIFIED: "UNVERIFIED",
  REJECTED: "REJECTED",
  ARCHIVED: "ARCHIVED",
};

export const ProductType = {
  DRUG: "DRUG",
  MEDICAL_DEVICE: "MEDICAL DEVICE",
  CONSUMABLE: "CONSUMABLE",
  MEDICAL_EQUIPMENT: "MEDICAL EQUIPMENT",
};
