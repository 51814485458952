import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { apiVersion, ProductType } from "../../controller/api/apiCore";
import "./edit_product.scss";
import Loader from "../reusables/Loader";
import { ReactComponent as DeleteIcon } from "../../res/images/cancel.svg";
import moment from "moment";
import { useSelector } from "react-redux";
import useNotification from "../../hooks/useNotification";
function EditProduct(props) {
  const { id, mode } = useParams();
  const notify = useNotification();
  const { user } = useSelector((state) => state);
  const [product, setProduct] = useState({});
  const [types, setTypes] = useState([]);
  const [images, setImages] = useState([]);
  const [addedImages, setAddedImages] = useState([]);
  const [formulations, setFormulations] = useState([]);
  const [routeOfAdmins, setRouteOfAdmin] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, SetLoading] = useState(true);
  const [deletedImages, setDeletedImages] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const form = useRef(null);
  const handleGetProduct = async (productId) => {
    let product = {};
    try {
      let req = await fetch(
        apiVersion +
          (mode === "newProduct" ? "/pending_product/" : "/product/") +
          productId
      );
      if (req.ok) {
        product = await req.json();
        setProduct(product);
        setImages(product.images);
      }
    } catch (error) {
      alert(error.message);
    }
    return product;
  };
  const handleGetFormulation = async (id) => {
    setIsDisabled(true);
    try {
      let req = await fetch(apiVersion + "/formulation/" + id);
      if (req.ok) {
        setFormulations(await req.json());
      }
      setIsDisabled(false);
    } catch (error) {
      console.log(error);
    }
  };

  //get route of administration
  const handleGetROA = async () => {
    setIsDisabled(true);
    try {
      let req = await fetch(apiVersion + "/roa");
      if (req.ok) {
        setRouteOfAdmin(await req.json());
      }
      setIsDisabled(false);
    } catch (error) {}
  };
  const getCategory = async (type) => {
    setIsDisabled(true);
    try {
      let req = await fetch(apiVersion + "/category/" + type);
      if (req.ok) {
        setCategories(await req.json());
      }
      setIsDisabled(false);
    } catch (error) {}
  };
  const getCompanies = async (type) => {
    try {
      setIsDisabled(true);
      let req = await fetch(apiVersion + "/brands");
      if (req.ok) {
        setCompanies(await req.json());
      }
      setIsDisabled(false);
    } catch (error) {}
  };
  const getTypes = async (type) => {
    try {
      let req = await fetch(apiVersion + "/types");
      handleGetFormulation(type);
      if (req.ok) {
        setTypes(await req.json());
      }
    } catch (error) {}
  };

  const handleRoaChange = (e) => {
    console.log(e.target.value0);
    handleGetFormulation(e.target.value);
  };

  const handleTypeChange = (e) => {
    let type = e.target.value;
    getCategory(type);
    setProduct((state) => {
      return { ...state, type };
    });
  };

  const handleDeleteImage = (src) => {
    if (window.confirm("Delete Image? Refresh page to recover")) {
      setImages((state) => state.filter((img) => img !== src));
      setDeletedImages((state) => [...state, { Key: src }]);
    }
  };

  useEffect(() => {
    (async () => {
      let product = id ? await handleGetProduct(id) : {};
      await getTypes();
      await getCategory(product.type);
      await getCompanies();
      await handleGetROA();
      await handleGetFormulation(product.roa);
      SetLoading(false);
      console.log(product);
    })();
  }, []);
  const handleSubmitPendingProduct = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    formData.append("deletedImages", deletedImages);
    formData.append("images", images);
    formData.append("merchant_id", product.merchant_id);
    formData.append("pendingProductId", product._id);

    try {
      let req = await fetch(apiVersion + "/product", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: "Bearer " + user.token,
        },
      });
      if (req.ok) {
        let product = await req.json();
        notify(product.name + " had been successfully approved");
      }
    } catch (error) {
      notify("Product Approval failed=>" + error.message);
    }
  };
  const handleEditProduct = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    formData.append("deletedImages", deletedImages);
    formData.append("images", images);
    formData.append("merchant_id", product.merchant_id);
    formData.append("_id", product._id);

    try {
      let req = await fetch(apiVersion + "/product", {
        method: "PATCH",
        body: formData,
        headers: {
          Authorization: "Bearer " + user.token,
        },
      });
      if (req.ok) {
        let product = await req.json();
        notify(product.name + " had been successfully Edited");
      }
    } catch (error) {
      notify("Product Approval failed=>" + error.message);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="edit-product-con">
      {" "}
      <h2>{mode === "newProduct" ? "Add New Product" : product.name}</h2>
      <form
        ref={form}
        onSubmit={
          mode === "newProduct" ? handleSubmitPendingProduct : handleEditProduct
        }
        className="edit-product-con__body"
      >
        <DetailForm title={"Type"} info="The Type of product">
          <select
            onChange={handleTypeChange}
            defaultValue={product?.type ?? ""}
            name="type"
          >
            {types.map((type) => (
              <option value={type.name} key={type._id}>
                {type.name}
              </option>
            ))}
          </select>
        </DetailForm>
        <DetailForm
          title={"Category"}
          info="The category the product belongs to"
        >
          <select
            name="primary_category"
            defaultValue={product?.primary_category ?? ""}
            disabled={isDisabled}
          >
            {categories.map((category) => (
              <option value={category.name} key={category._id}>
                {" "}
                {category.name}
              </option>
            ))}
          </select>
        </DetailForm>
        {product?.type === ProductType.DRUG ? (
          <>
            {" "}
            <DetailForm
              title={"ROA"}
              info="The route of administration of the drug, e.g Oral, Sublingual e.t.c."
            >
              <select
                onChange={handleRoaChange}
                defaultValue={product?.roa ?? ""}
                name="route_of_administration"
                disabled={isDisabled}
              >
                {routeOfAdmins.map((roa) => (
                  <option key={roa._id} value={roa.name}>
                    {roa.name}
                  </option>
                ))}
              </select>
            </DetailForm>
            <DetailForm title={"Formulation"} info="The name of the product">
              <select
                type="text"
                defaultValue={product?.formulation ?? ""}
                name="formulation"
                disabled={isDisabled}
              >
                {formulations.map((form) => (
                  <option value={form.name} key={form._id}>
                    {form.name}
                  </option>
                ))}
              </select>
            </DetailForm>
            <DetailForm
              title={"Brand Name"}
              info="The brand name of the product e.g Panadol, Eusadol, Tumol e.t.c"
            >
              <input
                type="text"
                name="brand_name"
                defaultValue={product?.brand_name ?? ""}
              />
            </DetailForm>
            <DetailForm
              title={"Generic Name"}
              info="The generic name of the product e.g Paracetamol, Acetaminophen"
            >
              <input
                type="text"
                name="generic_name"
                defaultValue={product?.generic_name ?? ""}
              />
            </DetailForm>
            <DetailForm
              title={"Strength"}
              info="The strength of the drug e.g 30mg, 100mls e.t.c"
            >
              <input
                type="text"
                name="strength"
                defaultValue={product?.strength ?? ""}
              />
            </DetailForm>
            <DetailForm
              title={"Packet Size"}
              info="The amount of items in a packet of drug"
            >
              <input
                type="text"
                name="pack_size"
                defaultValue={product?.pack_size ?? ""}
              />
            </DetailForm>
          </>
        ) : (
          <DetailForm title={"Name"} info="The name of the product">
            <input type="text" name="name" defaultValue={product?.name ?? ""} />
          </DetailForm>
        )}

        <DetailForm
          title={"Quantity"}
          info="The number of units of the product"
        >
          <input
            type="number"
            step={2}
            name="quantity"
            defaultValue={product?.quantity ?? ""}
          />
        </DetailForm>
        <DetailForm title={"Price"} info="The price of the product">
          <input
            type="number"
            step={2}
            name="price"
            defaultValue={product?.price ?? ""}
          />
        </DetailForm>
        <DetailForm
          title={"Company"}
          info="The name of the manufacturing company"
          disabled={isDisabled}
        >
          <select name="company" defaultValue={product?.company ?? ""}>
            {companies.map((company) => (
              <option key={company._id} value={company.name}>
                {company.name}
              </option>
            ))}
          </select>
        </DetailForm>
        <DetailForm title={"Nafdac Number"} info="The name of the product">
          <input
            type="text"
            name="nafdac"
            defaultValue={product?.nafdac ?? ""}
          />
        </DetailForm>
        <DetailForm
          title={"Mfd Date"}
          info="The date the drug was manufactured"
        >
          <input
            type="date"
            name="manufacture_date"
            defaultValue={moment(product?.manufacture_date).format(
              "YYYY-MM-DD"
            )}
          />
        </DetailForm>
        <DetailForm title={"Exp Date"} info="The expiration date of the drug">
          <input
            type="date"
            name="expiry_date"
            defaultValue={moment(product?.expiry_date).format("YYYY-MM-DD")}
          />
        </DetailForm>
        <DetailForm title={"Description"} info="The Description of the product">
          <textarea
            name="description"
            rows={20}
            defaultValue={product?.description ?? ""}
          />
        </DetailForm>
        <DetailForm title={"Images"} info={"Existing images on product"}>
          <div className="edit-product-con__body__images">
            {images?.map((image) => (
              <ImageCard
                key={image}
                src={apiVersion + "/image/" + image}
                handleDeleteImage={() => handleDeleteImage(image)}
              />
            ))}
          </div>
        </DetailForm>
        <DetailForm title={"Add Image"} info={"Add image to product"}>
          <div className="edit-product-con__body__images">
            <div className="edit-product-con__body__images__image-card">
              <input
                multiple={true}
                onChange={(e) => {
                  for (let i = 0; i < e.target.files.length; i++) {
                    const url = URL.createObjectURL(e.target.files[i]);
                    setAddedImages((state) => [...state, url]);
                  }
                }}
                type="file"
                name="image_files"
                accept=".jpeg,.jpg,.png"
              />
            </div>
            {addedImages.map((image) => (
              <div className="edit-product-con__body__images__image-card">
                <DeleteIcon
                  onClick={() =>
                    setAddedImages((state) =>
                      state.filter((img) => img !== image)
                    )
                  }
                />
                <img src={image} alt="" />
                <p>{image}</p>
              </div>
            ))}
          </div>
        </DetailForm>
        <DetailForm
          title="Search Tags"
          info={
            "words to aid search for this product, separate each tag with a comma, eg,antacid,anti-flatulence,anti-constipation"
          }
        >
          <input
            type="text"
            name="tags"
            defaultValue={product?.tags?.join(",") ?? ""}
          />
        </DetailForm>
        <button type="submit">Save</button>
      </form>
    </div>
  );
}

const DetailForm = ({ title, info, name, type = "text", children }) => (
  <div className="detail-form">
    <label>{title}</label>
    <div className="detail-form__body">
      {children}
      <small>{info}</small>
    </div>
  </div>
);

const ImageCard = ({ src, handleDeleteImage }) => {
  return (
    <div className="edit-product-con__body__images__image-card">
      <DeleteIcon onClick={handleDeleteImage} />
      <img src={src} alt="" />
      <p>{src}</p>
    </div>
  );
};

export default EditProduct;
