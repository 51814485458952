import React, { useEffect, useState } from "react";
import "../../res/styles/login.scss";
import login_img from "../../res/images/login.svg";
import { loginUser } from "../../controller/api/authApi";
import { loginUser as loginDispatch } from "../../model/store/user";
import { useDispatch, useSelector } from "react-redux";
import { setMerchant } from "../../model/store/merchantReducer";
import { Link, useNavigate } from "react-router-dom";
const LoginPage = (props) => {
  const handlePlaceHolder = () => {};
  const dispatch = useDispatch();
  const { isAuthorized } = useSelector((state) => state.user);
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let form = new FormData(e.target);
    let req = await loginUser(form);
    try {
      if (req.status === 200) {
        let data = await req.json();
        console.log(data);
        let userObject = data.admin;
        userObject.token = data.token;
        await dispatch(loginDispatch(userObject));

        switch (userObject.type) {
          case "merchant":
            dispatch(setMerchant(userObject));
            navigate("/merchant/dashboard");
            break;
          case "admin":
            navigate("/admin/dashboard");
            break;
          default:
            break;
        }
      }
    } catch (error) {
      alert(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {}, []);
  return (
    <div className={"form_con "}>
      <h2>Login</h2>
      <form onSubmit={handleSubmit} className="form">
        <div className="input_container">
          <label className="label">Email/Phone Number</label>
          <input
            onFocus={handlePlaceHolder}
            type="text"
            name="email"
            required
            className="input"
          />
        </div>
        <div className="input_container">
          <label className="label">Password</label>
          <input className="input" name="password" type="password" required />
        </div>
        <button className="login_btn" disabled={isLoading} type="submit">
          {isLoading ? <div className="login_loader"></div> : <p>Log In</p>}
        </button>
      </form>
      <div className="forgot_password">
        <Link to="/signup">Create Account</Link>
        <a href="http://#">Forgot your password?</a>
      </div>
    </div>
  );
};

export default LoginPage;
