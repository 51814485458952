import React from "react";
//pointless commetss
function Table({ paged, handleToMerchant, prev }) {
  return (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>#</th>
          <th>Buisness Name</th>
          <th>New Items</th>
          <th>Products</th>
          <th>Orders</th>
          <th>Rating</th>
          <th>Sales (&#8358;)</th>
        </tr>
      </thead>
      <tbody>
        {paged.map((merchant, index) => (
          <tr onClick={() => handleToMerchant(merchant)} key={merchant._id}>
            <td>{index + prev + 1}</td>
            <td>{merchant?.business_name}</td>
            <td>{merchant?.pending_products.length}</td>
            <td>{merchant?.products?.length}</td>
            <td>{merchant?.orders.length}</td>
            <td>{merchant?.rating} stars</td>
            <td>{merchant?.sales}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
