import { combineReducers } from "redux";
import cart from "./cart";
import account from "./account";
import itemCategory from "./itemList";
import loader from "./loader";
import categoryMenu from "./categorymenu";
import alert from "./alert";
import preview from "./preview";
import items from "./items";
import user from "./user";
import currentItem from "./currentItem";
import products from "./products";
import merchant from "./merchantReducer";
export default combineReducers({
  account,
  alert,
  cart,
  categoryMenu,
  currentItem,
  itemCategory,
  items,
  loader,
  preview,
  products,
  user,
  merchant,
});
