import { Route, useLocation, Routes } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all";
import { Provider, useSelector } from "react-redux";
import configureStore from "./model/store/configureStore";
import AlertBox from "./components/reusables/AlertBox";
import ProtectedRoute from "./components/ProtectedRoute";
import { lazy, Suspense } from "react";
import AdminHome from "./components/admin/AdminHome";
import AdminMerchants from "./components/admin/merchant/AdminMerchants";
import AdminMerchantAccount from "./components/admin/merchant/admin_merchant_options/AdminMerchantAccount";
import AdminCustomers from "./components/admin/customers/AdminCustomers";
import Customer from "./components/admin/customers/Customer";
import Orders from "./components/account components/Orders";
import EditProduct from "./components/admin/EditProduct.jsx";
import MerchantLanding from "./components/merchants/MerchantLanding";
import SignUp from "./components/home_components/signup_page";
import LoginPage from "./components/home_components/loginpage";
import MerchantDashboard from "./components/merchants/MerchantDashboard";
import MerchantProducts from "./components/merchants/MerchantProducts";
import MerchantOrders from "./components/merchants/MerchantOrders";
import MerchantReviews from "./components/merchants/MerchantReviews";
import AddItem from "./components/merchants/resusables/AddItem";
import AddProduct from "./components/merchants/resusables/AddProduct";
import Loader from "./components/reusables/Loader";
const store = configureStore();
const HomePage = lazy(() => import("./components/home_components/home_page"));
const Mart = lazy(() => import("./components/mart_components/mart_landing"));
const MerchantParent = lazy(() =>
  import("./components/merchants/MerchantParent")
);
const CheckOut = lazy(() =>
  import("./components/checkout components/Checkout")
);
const UserOption = lazy(() =>
  import("./components/account components/UserOptions")
);
const AdminLanding = lazy(() => import("./components/admin/AdminLanding"));
//const EditProduct = lazy(() => import("./components/admin/EditProduct.jsx"));
const Cat = lazy(() => import("./Cat.jsx"));
const routes = [
  { path: "/admin", component: AdminLanding, isPrivate: true },
  { path: "/account", component: UserOption, isPrivate: true },
  { path: "/checkout", component: CheckOut, isPrivate: true },
  { path: "/merchant", component: MerchantParent, isPrivate: true },
  // { path: "/edit_item/:productId", component: EditProduct, isPrivate: true },
  { path: "/cat", component: Cat },
  { path: "/", component: HomePage },
  //{ path: "/", component: Mart },
];
const adminRoutes = [
  { path: "admin", Component: AdminHome },
  { path: "merchants", Component: AdminMerchants },
  { path: "merchants_account/:merchantId", Component: AdminMerchantAccount },
  { path: "customers", Component: AdminCustomers },
  { path: "orders", Component: Orders },
  { path: "edit_item/:productId", Component: EditProduct },
  { path: "dashboard", Component: AdminHome },
];
function App() {
  return (
    <Provider store={store}>
      {/* <TransitionGroup> */}
      {/* <CSSTransition key={location.key} classNames="fade" timeout={100}> */}
      <div className="App">
        <AlertBox />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<HomePage />}>
              <Route path="/" element={<LoginPage />} />
              <Route path="/signup" element={<SignUp />} />
            </Route>
            <Route path="admin" element={<AdminLanding />}>
              <Route path="merchants" element={<AdminMerchants />} />
              <Route
                path="merchant-account/:id"
                element={<AdminMerchantAccount />}
              />
              <Route path="customers" element={<AdminCustomers />} />
              <Route path="customer/:id" element={<Customer />} />
              <Route path="orders" element={<Orders />} />
              <Route path="dashboard" element={<AdminHome />} />
              <Route path="edit-product/:id/:mode" element={<EditProduct />} />
            </Route>

            <Route path="merchant" element={<MerchantParent />}>
              <Route element={<MerchantLanding />}>
                {" "}
                <Route path="dashboard" element={<MerchantDashboard />} />
                <Route path="products" element={<MerchantProducts />} />
                <Route path="orders" element={<MerchantOrders />} />
                <Route path="reviews" element={<MerchantReviews />} />
                <Route path="add-item" element={<AddProduct />} />
              </Route>
            </Route>
            <Route path="cat" element={<Cat />} />
          </Routes>
        </Suspense>
      </div>
      {/* </CSSTransition> */}
      {/* </TransitionGroup> */}
    </Provider>
  );
}
export default App;
