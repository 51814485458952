import React, { memo, useState } from "react";
import "./merchant.scss";
import { ReactComponent as DashboardIcon } from "../../res/images/dashboard_icon.svg";
import { ReactComponent as ProductIcon } from "../../res/images/products_icon.svg";
import { ReactComponent as OrdersIcon } from "../../res/images/orders_icon.svg";
import { ReactComponent as ReviewIcon } from "../../res/images/reviews.svg";
import {
  Route,
  Redirect,
  useLocation,
  Outlet,
  useNavigate,
} from "react-router-dom";
import MerchantDashboard from "./MerchantDashboard";
import MerchantProducts from "./MerchantProducts";
import MerchantOrders from "./MerchantOrders";
import MerchantReviews from "./MerchantReviews";
import { useEffect } from "react";
import AddForm from "./resusables/AddForm";
import AddItem from "./resusables/AddItem";
function MerchantLanding(props) {
  let navigate = useNavigate();
  let location = useLocation();
  const [indicator, setIndicator] = useState(0);
  const handleMenu = (indicator, path) => {
    setIndicator(indicator);
    navigate(path);
  };
  useEffect(() => {
    location.pathname.includes("dashboard")
      ? setIndicator(0)
      : location.pathname.includes("products")
      ? setIndicator(1)
      : location.pathname.includes("orders")
      ? setIndicator(2)
      : setIndicator(3);
  }, []);
  return (
    <div className="merchant-body">
      <div className="merchant-body__nav-bar">
        <div
          style={{ left: `${indicator * 25}%` }}
          className="animated-line"
        ></div>
        <MenuOption
          onClick={() => handleMenu(0, "/merchant/dashboard")}
          SVG={DashboardIcon}
          title="Dashboard"
          options={[]}
        />
        <MenuOption
          onClick={() => handleMenu(1, "/merchant/products")}
          SVG={ProductIcon}
          title="Products"
          options={[]}
        />
        <MenuOption
          onClick={() => handleMenu(2, "/merchant/orders")}
          SVG={OrdersIcon}
          title="Orders"
          options={[]}
        />
        <MenuOption
          onClick={() => handleMenu(3, "/merchant/reviews")}
          SVG={ReviewIcon}
          title="Reviews"
          options={[]}
        />
      </div>
      <main className="merchant-body__main">
        {/* <Route
          path="/"
          render={() => <Redirect to={"/merchant/dashboard"} />}
        /> */}
        <Outlet />
      </main>
    </div>
  );
}

function MenuOption({ SVG, title, options, onClick }) {
  return (
    <div onClick={() => onClick()} className="merchant-body__nav-bar__menu">
      <SVG />
      <p>{title}</p>
    </div>
  );
}
export default memo(MerchantLanding);
