import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "alert",
  initialState: { isVisible: false, message: "", isError: true },
  reducers: {
    showNotification: (state, { payload }) => {
      return { ...payload, isVisible: true };
    },
    hideNotification: (state, { payload }) => {
      return { ...state, isVisible: false };
    },
  },
});
export const { showNotification, hideNotification } = slice.actions;
export default slice.reducer;
